import React from 'react';
import { Refund, OrderDetailPageLabels, RefundMonetaryComponent, RefundAmount } from '../component';
import RefundComponentRow from "./RefundComponentRow";
import { formatCurrency, formatReturnAmount, formatMonDDYYY } from '../common/FormatUtils';
import { getLocale } from '../../Utils'
import ErrorBoundary from '../../ErrorBoundary';

interface RefundProp {
    refund?: Refund,
    labels?: OrderDetailPageLabels,
    loading?: boolean,
}

interface RefundState {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
}

class RefundRow extends React.Component<RefundProp, RefundState> {

    private button: any;
    private bottomSheet: any;

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        this.state.bottomSheet.current.show();
    }

    _findAndRemoveByKey(array: any[], key: any) {
        let result = undefined;
        const pos = array.findIndex(el => el.name === key);
        if (pos >= 0) {
            result = array.splice(pos, 1)[0];
        }
        return result;
    }

    _hasPositiveRefundAmount(refundAmount: RefundAmount) {
        return refundAmount && refundAmount.amount && (parseFloat(refundAmount.amount) > 0)
    }

    render() {
        if (this.props.loading) {
            return (
                <pui-stack direction='horizontal'>
                    <pui-stack-item>
                        <div className="pui-loading-text-medium small-margin-top small-margin-bottom" />
                    </pui-stack-item>
                    <pui-stack-item>
                        <div className="pui-loading-text-medium small-margin-top small-margin-bottom" />
                    </pui-stack-item>
                </pui-stack>
            )
        }

        if (!this.props.refund || !this.props.labels) {
            return (<></>);
        }

        let components: RefundMonetaryComponent[] = Array.from(this.props.refund.refundMonetaryComponents);

        /**
         * Principal refund component needs to be taken care separtely for zero dollar reversal refund case
         * In case of $0 copay order reversal, we would like to show the refund amount = -$0.00 as long as customer paid nothing for this order.
         * 
         * 1) If total amount is not zero, then show only non-zero components.
         * 2) If the total is zero, then show only ourprice Principal as zero
         * 
         */
        let principalComponent = this._findAndRemoveByKey(components, "Principal");
        let validComponents = components.filter(c => this._hasPositiveRefundAmount(c.baseAmount) && c.nameLabel);

        return (
            <>
                <pui-stack
                    direction='horizontal'
                    ref={this.state.button}
                    onClick={(e: any) => this._openBottomSheet(e)}
                    class="clickable">
                    <pui-stack-item flowDirection="horizontal"
                        mainAxisArrangement="space-between">
                        <pui-text textColor="link"  fontWeight="bold" input={this.props.labels.refundOn + " " + formatMonDDYYY(this.props.refund.creationDate, getLocale())} textAlign="left" />
                        <pui-icon slot="icon" imgClass='chevron-right' spacingLeft="mini" spacingRight="medium" spacingTop="mini"></pui-icon>
                    </pui-stack-item>
                    <pui-stack-item>
                        <pui-text textColor="link" fontWeight="bold" input={formatCurrency(formatReturnAmount(this.props.refund.refundTotal.amount), this.props.refund.refundTotal.currency, getLocale())} textAlign="right" />
                    </pui-stack-item>
                </pui-stack>
                <ErrorBoundary>
                    <pui-bottom-sheet id="refund-modal" data-csa-c-content-id="refund-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-detail" hideLink ref={this.state.bottomSheet}>
                        <pui-heading input={this.props.labels.refundInformation} textSize='large' spacingTop="small" spacingBottom="medium" />
                        {
                            /**
                             * 1) If total amount is not zero , then show only non-zero components. 
                             * 2) if the total is zero, we show only ourprice principal as zero
                             */
                            principalComponent && (this._hasPositiveRefundAmount(principalComponent.baseAmount) || parseFloat(this.props.refund.refundTotal.amount) === 0) ?
                                <RefundComponentRow amount={principalComponent.baseAmount} nameLabel={principalComponent.nameLabel} /> : <></>
                        }
                        {
                            // just in case we get any other refund component type we don't handle yet, currently we only have Shipping and Principal refund component 
                            validComponents.map(c => <RefundComponentRow amount={c.baseAmount} nameLabel={c.nameLabel} key={c.nameLabel} />)
                        }
                        {
                            this._hasPositiveRefundAmount(this.props.refund.taxRefundTotal) && this.props.labels.taxRefund ?
                                <RefundComponentRow amount={this.props.refund.taxRefundTotal} nameLabel={this.props.labels.taxRefund} /> : <></>
                        }
                        <RefundComponentRow fontWeight="bold" noDivider={true} amount={this.props.refund.refundTotal} nameLabel={this.props.labels.refundTotal} />
                    </pui-bottom-sheet>
                </ErrorBoundary>
            </>
        )
    }
}

export default RefundRow;