import React from 'react';

import { I18NContext } from '../I18NContext';
import PUILink from './common/PUILink';
import ContactCustomerCare from './common/ContactCustomerCare';
import ManagePillpackSettings from './common/ManagePillpackSettings';
import {ListDelimiter} from "./common/ListDelimiter";

interface HelpProps {
    invoiceId: string;
    viewInvoice: string;
    contactCustomerCare: string;
    managePillpackSettings?: string;
    isPillPack?:boolean;
}

class Help extends React.Component<HelpProps, {}> {

    render() {

        return (
            <ListDelimiter delimiter={<pui-divider/>}>
                {this.props.children}
                {!this.props.isPillPack && <PUILink csaId="invoice" text={this._generateHelpLinkContent(this.props.viewInvoice)} href={`/invoice/${this.props.invoiceId}`} replace={true} align="center"/>}
                {this.props.isPillPack && this.props.managePillpackSettings && <ManagePillpackSettings managePillpackSettings={this.props.managePillpackSettings}/>}
                <ContactCustomerCare contactCustomerCare={this.props.contactCustomerCare}/>
            </ListDelimiter>
        );
    }

    _generateHelpLinkContent(linkName: string) {
        return `<pui-stack direction='vertical'>
            <pui-stack-item-button buttonPadding="none">
                <pui-text input='${linkName}' textSize='medium' color="black" paddingTop="medium" paddingBottom="medium" />
            </pui-stack-item-button>
        </pui-stack>`
    }
}

Help.contextType = I18NContext;

export default Help;