import React, { FunctionComponent } from 'react';
import { Alert } from '../component';
import { AlertTypeUtility } from './AlertTypeUtility';
import { CsaTagProps } from '../csa/utils';

interface GenericAlertProps {
    alert: Alert;
    csaTagProps: CsaTagProps;
}

const GenericAlert: FunctionComponent<GenericAlertProps> = ({ alert, csaTagProps }) => {
    const article = alert.article;
    const { imgClass, status, titleColor, textColor } = AlertTypeUtility.getAlertTypeMapValues(alert.alertType);

    return (
        <pui-banner
            direction="vertical"
            flex-direction="column"
            status={status}
            spacingTop="small"
            spacingBottom="small"
            data-csa-c-content-id={csaTagProps.contentId}
            data-csa-c-type={csaTagProps.type}
            data-csa-c-slot-id={csaTagProps.slotId}
        >
            <pui-stack direction="vertical" flex-direction="column">
                <pui-stack direction="horizontal" mainAxisArrangement="start">
                    {imgClass !== '' && <pui-icon imgClass={imgClass} spacingRight="mini" />}
                    <pui-heading textColor={titleColor} input={article.headline} textSize="small" />
                </pui-stack>
                {article.sublines && article.sublines.length > 0 && (
                    <pui-stack>
                        <pui-stack-item overflow="true" spacingTop="mini">
                            {article.sublines.map((subline, index) => (
                                <pui-text key={index} input={subline} inline textColor={textColor} />
                            ))}
                        </pui-stack-item>
                    </pui-stack>
                )}
            </pui-stack>
        </pui-banner>
    );
};

export default GenericAlert;