import Cookies from "js-cookie";
import { Order } from "./components/component";
import RebillOrderType from "./common/Types"

const DEFAULT_LOCALE = "en-US";

 /**
  * This is a util function to get locale of current session. 
  * Note that we will get locale only by looking up the Amazon retail's language preference cookie - i.e. lc-main in prod or lc-tacbus in dev/beta.
  * The return locale will be in xx-XX format, like en-US or es-US.
  * Load retail language preference first, otherwise load browser language and default language.
  */
export const getLocale = (): string => {
    return (Cookies.get('lc-main') || Cookies.get('lc-tacbus') || navigator.language || DEFAULT_LOCALE).replace("_", "-");
}

/**
* This is a util function is to check if a order is rebill order type or not. 
* Orders with order type REBILL_ORDER or REBILL_AND_PARENT_ORDER fall into this category
*/
export const checkRebillOrder = (order: Order): boolean => {
  return (order.reverseRebillOrderDetails?.rebillOrderType === RebillOrderType.REBILL_ORDER.toString() 
          || order.reverseRebillOrderDetails?.rebillOrderType === RebillOrderType.REBILL_AND_PARENT_ORDER.toString());
}
export enum CSA_MARKER {
    TIME_OF_CLICK = 'tc',
    ABOVE_THE_FOLD = 'aboveTheFold',
    CRITICAL_FEATURE = 'criticalFeature',
    FUNCTIONAL ='functional'
}