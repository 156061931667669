import React from 'react';
import ExternalLink from './ExternalLink';
import { I18NContext } from '../../I18NContext';

interface ContactCustomerCareProps {
    contactCustomerCare: string;
}

export class ContactCustomerCare extends React.Component<ContactCustomerCareProps, {}> {
    render() {
        return (<ExternalLink href='/help/'>
            <pui-stack id="link-to-customer-care" data-csa-c-type="link" data-csa-c-action="navigate" data-csa-c-content-id="link-to-customer-care"
                       data-csa-c-slot-id="contact-customer-care" direction="vertical">
                <pui-stack-item-button buttonPadding="none">
                    <pui-text input={this.props.contactCustomerCare} textSize='medium' color="black" paddingTop="medium" paddingBottom="medium" />
                </pui-stack-item-button>
            </pui-stack>
        </ExternalLink>);
    }
}

ContactCustomerCare.contextType = I18NContext;

export default ContactCustomerCare;