import React from 'react';

import { Annotation } from "../component";

interface ItemAnnotationProps {
    annotations: Annotation[];
    textSize?: string;
    textColor?: string;
}

function generateAnnotationLine(annotation: Annotation, textSize: string, textColor?: string) {
    const icon = annotation.annotationType === "PayFixup"
        ? <pui-icon imgClass="status-error-icon" spacingRight="small" />
        : <pui-icon imgClass="status-info-icon" spacingRight="small" />
    
    if (textColor === undefined) {
        textColor = 'black';
    }
    return (<pui-stack
        key={annotation.label}
        style={{ textDecoration: "none" }}
        direction="horizontal"
        mainAxisArrangement="start"
    >
        {icon}
        <pui-text input={annotation.label} textColor={textColor} textSize={textSize} />
    </pui-stack >)
}

export function generateAnnotationLinePillpack(annotation: Annotation, textSize: string, textColor: string) {
    if (annotation.annotationType !== "PayFixup" && annotation.annotationType !== "Refund" && annotation.annotationType !== "ItemCancelledOn") {
        return null;
    }

    let annotationColor = textColor;

    switch (annotation.annotationType) {
        case "PayFixup":
        case "ItemCancelledOn":
            annotationColor = "red";
            break;
        case "Refund":
            annotationColor = "green";
            break;
        default:
            annotationColor = textColor;
    }

    return (
        <pui-text key={annotation.label} input={annotation.label} textColor={annotationColor} textSize={textSize} fontWeight="bold" />
    );
}

export default ({annotations, textSize="medium", textColor}: ItemAnnotationProps) => {
    const duplicates = new Set();

    const suppressPayFixup = annotations.some(
        (annotation) => annotation.annotationType === "SuppressPayFixup");

    const annotationChild = annotations
        .filter(annotation => annotation.annotationType !== "Cancel")
        .filter(annotation => annotation.annotationType !== "SuppressPayFixup")
        .filter(annotation => suppressPayFixup?annotation.annotationType !== "PayFixup":true)
        .filter(annotation => {
            const isDuplicate = duplicates.has(annotation.annotationType);
            duplicates.add(annotation.annotationType);
            return !isDuplicate;
        })
        .map(annotation => {
            duplicates.add(annotation.annotationType)
            return generateAnnotationLine(annotation, textSize, textColor);
        });

        return (<> 
            {annotationChild}
        </>)
}