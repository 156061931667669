import React, {SyntheticEvent, useRef} from "react";
import {ListDelimiter} from "./common/ListDelimiter";
import {Link} from "react-router-dom";
import {MedicationGroup} from "./component";
import MedicationCard from "./common/MedicationCard";

interface InvoiceMenuProps {
  title: string
  invoiceMenu: MedicationGroup[]
}

/**
 * Invoice Menu with medication card and link to Invoice Page
 * @param props
 * @constructor
 */
function InvoiceMenu(props: InvoiceMenuProps) {

  return (
    <pui-stack direction={'vertical'}>
      <pui-heading input={props.title} spacingTop="medium"/>
      <ListDelimiter delimiter={<pui-divider/>}>
        {props.invoiceMenu.map(((med, index) => <Link to={`/invoice/${med.id}`}>
          <pui-stack-item-button key={`invoice-${index}`} overflow={true} buttonPadding={"mini"}>
            <MedicationCard headline={med.headline} subline={med.subline} imageURL={med.photoUrl}/>
          </pui-stack-item-button>
        </Link>))}
      </ListDelimiter></pui-stack>
  );
}

interface ViewInvoiceMenuProps {
  title: string
  buttonName: string
  invoiceMenu: MedicationGroup[]
}

/**
 * Click Button and View Invoice Menu.
 * @param props required buttonName and title for invoice Menu and Item
 * @constructor
 */
export function ViewInvoiceMenu(props: ViewInvoiceMenuProps) {
  const bottomSheetRef = useRef<any>()
  const invoicesRef = useRef<any>()


  const showBottomSheet = (e: SyntheticEvent) => {
    e.preventDefault()
    if (invoicesRef.current) {
      bottomSheetRef?.current?.show();
    }
  }

  return <>
    <pui-stack direction="vertical" ref={invoicesRef} class="clickable" onClick={showBottomSheet}>
      <pui-stack-item-button
        id="button-to-invoice-menu"
        data-csa-c-type="button"
        data-csa-c-action="click"
        data-csa-c-content-id="button-to-invoice-menu"
        data-csa-c-slot-id="invoice-menu"
        overflow
        buttonPadding="none"
      >
        <pui-text input={props.buttonName} textSize="medium" color="black" paddingTop="medium" paddingBottom="medium" />
      </pui-stack-item-button>
    </pui-stack>
    <pui-bottom-sheet ref={bottomSheetRef} id="invoice-menu-bottom-sheet">
      <InvoiceMenu invoiceMenu={props.invoiceMenu} title={props.title}/>
    </pui-bottom-sheet>
  </>
}