import { ItemGroupSummary } from "../component";

export const getRequestId = (): string | undefined => {
  const root = document.getElementById('root');
  if (root) {
    const requestId = root.getAttribute('requestId');
    if (requestId) {
      return requestId;
    }
  }
  return undefined;
};

const PILL_PACK_NAME = 'pillpack';
export const REF_TAGS = {
  TRACKER_TO_SUMMARY: "pyo_tracker_summary",
  SEE_FUTURE_DELIVERY_TO_MANAGE_FUTURE_DELIVERIES: "pyo_to_mfd",
  DELIVERY_CARD_TO_TRACKER_PAGE: "pyo_to_tracker",
  DELIVERY_CARD_TO_SUMMARY_PAGE: "pyo_to_summary",
}

const generatePillPackRefTags = <T extends Record<string, string>>(prefix: string, refTags: T): Record<keyof T, string> => {
  return Object.entries(refTags).reduce((obj, [key, value]) => {
    return {
      ...obj,
      [key]: `${prefix}_${value}`
    };
  }, {} as Record<keyof T, string>);
};
export const PILL_PACK_REF_TAGS = generatePillPackRefTags(PILL_PACK_NAME, REF_TAGS);

export const generateUrlWithRefTag = (url: string, refTag: string) => {
  const searchParams = new URLSearchParams();
  searchParams.set('ref_', refTag)
  return `${url}?${searchParams.toString()}`
}

export const generateCsaTagSuffix = (isPillPack: boolean): string => {
  return isPillPack ? `-${PILL_PACK_NAME}` : ''
}

export interface CsaTagProps {
  contentId: string;
  type: string;
  slotId: string;
}

export const generateDeliveryCardCsaTag = (index: number, suffix: string): CsaTagProps => {
  return {
    contentId: `delivery-card-${suffix}`,
    type: "card",
    slotId: `delivery-card-${index}`
  }
}

export const generateCsaTagProps = (contentId: string, type: string, slotId: string): CsaTagProps => {
  return {
    contentId,
    type,
    slotId,
  };
};

export const hasBottleOrPackets = (itemGroupSummary: ItemGroupSummary) => {
  const bottleMedsLength = itemGroupSummary?.bottleGroupSummary?.itemGroups?.length;
  const pillPackMedsLength = itemGroupSummary?.pillPackGroupSummary?.itemGroups?.length;
  return bottleMedsLength > 0 || pillPackMedsLength > 0
}
export const generateUpcomingDeliveryCardCsaTag = (index: number, summary: ItemGroupSummary) => {
  return generateDeliveryCardCsaTag(index, 'upcoming' + generateCsaTagSuffix(hasBottleOrPackets(summary)))
}
export const generatePastDeliveryCardCsaTag = (index: number, summary: ItemGroupSummary) => {
  return generateDeliveryCardCsaTag(index, 'past' + generateCsaTagSuffix(hasBottleOrPackets(summary)))
}