import React from "react";
import { GroupType } from "../../common/Types";
import { CardStyle } from "../common/MedicationCard";

export interface MedicationGroupHeaderProps {
  heading: string;
  subHeading?: string;
  groupType: GroupType | string;
  children?: React.ReactNode;
  cardStyle?: CardStyle;
}

export function MedicationGroupHeader(props: MedicationGroupHeaderProps) {
  const iconClass = props.groupType === GroupType.PILLPACK ? "pillpack-icon" : "medication-bottle-icon";
  const imgSize = props.cardStyle === CardStyle.HEADLINE_MED_50PX_IMG ? "50px" : "48px";
  const detailsSpacingLeft = props.cardStyle === CardStyle.HEADLINE_LARGE ? "medium" : "med-small";

  return (
    <pui-product-card
      leftsideiconclass={iconClass}
      leftsideimgwidth={imgSize}
      leftsideimgheight={imgSize}
      noborder="true"
      noshadow="true"
      nospacing="true"
      nopadding="true"
      spacingTop="medium"
    >
      <pui-section spacingLeft={detailsSpacingLeft}>
        <pui-stack direction="vertical" overflow="true" >
          <pui-stack-item overflow="true" style={{ paddingTop: '3px' }}>
            <pui-text
              data-ref="headline"
              textSize={"medium"}
              fontweight="bold"
              input={props.heading}
            />
          </pui-stack-item>
          <pui-stack-item overflow="true" spacingTop="mini">
            <pui-text
              data-ref="rx-supply-label"
              textSize="small"
              input={props.subHeading}
            />
          </pui-stack-item>
        </pui-stack>
        {props.children}
      </pui-section>
    </pui-product-card>
  );
}