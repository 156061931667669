import React, {ReactNode} from "react";

interface MedicationGroupAccordionCardProps {
  children: ReactNode;
  divider?: ReactNode;
  noCollapse?: boolean;
  alertMessage?: ReactNode;
}

/**
 * @param props
 * @constructor
 */
export default function MedicationGroupAccordionCard(props: MedicationGroupAccordionCardProps) {
  if (!props.children) {
    return null;
  } else {
    return <pui-stack spacingLeft>
      {props.noCollapse ?
          <pui-group-accordion nocollapse>{props.children}</pui-group-accordion> :
          <pui-group-accordion>{props.children}</pui-group-accordion>}
      {props.alertMessage}
      {props.divider}
    </pui-stack>
  }
}
