import React, { ReactNode } from "react";

interface MedicationGroupCardProps {
  header?: ReactNode;
  children: ReactNode;
  marginLeft?: string;
}

export default function MedicationGroupCard(props: MedicationGroupCardProps) {
  const marginLeft = props.marginLeft || "0px";
  if (!props.children) {
    return null;
  } else {
    return (
      <pui-group-accordion-item>
        <div slot="header">{props.header}</div>
        <pui-section>
          <div style={{ marginLeft }}>{props.children}</div>
        </pui-section>
      </pui-group-accordion-item>
    );
 }
}