import React from 'react';
import { I18NContext } from '../../I18NContext';

interface CancelFormSelectV2Props {
    cancelFormSelectItem: CancelFormSelectV2Item;
    isAutoRefill?: boolean;
    selectButton: (disable: boolean) => void;
}

export interface CancelFormSelectV2Item {
    headline: string;
    subline: string;
    autoRefillLine: string
}

interface CancelFormSelectV2State {
    buttonRef: React.RefObject<any>;
    cardRef: React.RefObject<any>;
    selected: boolean;
}

class CancelFormSelectV2 extends React.Component<CancelFormSelectV2Props, CancelFormSelectV2State> {

    constructor(props: any) {
        super(props);

        this.state = {
            buttonRef: React.createRef(),
            cardRef: React.createRef(),
            selected: true
        }
    }

    canCheckButtonRef() {
        return this.state.buttonRef.current && typeof this.state.buttonRef.current.check === 'function';
    }

    canSelectCardRef() {
        return this.state.cardRef.current && typeof this.state.cardRef.current.select === 'function';
    }

    enableSelectButton() {
        if (this.canCheckButtonRef()) {
            this.state.buttonRef.current.check();
        }
        if (this.canSelectCardRef()) {
            this.state.cardRef.current.select();
        }
    }

    disableSelectButton() {
        if (this.canCheckButtonRef()) {
            this.state.buttonRef.current.uncheck();
        }
        if (this.canSelectCardRef()) {
            this.state.cardRef.current.deselect();
        }
    }

    setButton() {
        if (this.state.selected) {
            this.enableSelectButton();
        } else {
            this.disableSelectButton();
        }   
    }

    componentDidMount() {
        this.enableSelectButton();
    }

    render() {
        const { cancelFormSelectItem } = this.props;
        //Can sometimes be duplicate of _onSelect to ensure that card and button refs are aligned
        this.setButton();
        return (
            <>
                <pui-selector-item value={cancelFormSelectItem} ref={this.state.cardRef} onClick={() => this._onSelect()}>
                    <pui-section flowDirection='horizontal' mainAxisArrangement='start' secondaryAxisArrangement='start' spacingTop='small' spacingBottom='small'>
                        <pui-section-column spacingBottom='medium'>
                            <pui-checkbox ref={this.state.buttonRef} onClick={() => this._onSelect()}/>
                        </pui-section-column>
                        <pui-section-column >
                            <pui-text input={cancelFormSelectItem.headline} fontWeight='bold' />
                            <pui-text input={cancelFormSelectItem.subline} />
                            {(this.props.isAutoRefill)  && <pui-text input={cancelFormSelectItem.autoRefillLine}/>}
                        </pui-section-column>
                    </pui-section>
                </pui-selector-item>
            </>
        )
    }

    _onSelect() {
        this.setState({ selected: !this.state.selected });
        this.props.selectButton(this.state.selected);
        this.setButton();
    }


}

CancelFormSelectV2.contextType = I18NContext;

export default CancelFormSelectV2;