/**
 * Enable page scrolling on Your-Orders page after a redirection from pui-bottom-sheet
 * Needed to revert the changes to css made here - https://tiny.amazon.com/1gf4eb299/codeamazpackPharblob37fcsrc
 * These css changes were preserved from loading the Cancel widget, which triggers pui-bottom-sheet and the locked css.
 * Further Reference link: https://tiny.amazon.com/w1on22o6/codeamazpackAmazblobbd29src
 */
export const unlockPageScroll = () => {
    const body = document.querySelector('body');
    const scrollDisabledClass = 'pui-scroll-disabled';
    const isMobile = document.documentElement.classList.contains('a-mobile');
    if(body != null) {
        if (isMobile) {
            const scrollY = document.body.style.top;
            body.classList.remove(scrollDisabledClass);
            body.style.top = '';
            window.scrollTo(0, Math.abs(parseInt(scrollY || '0')));
        } else {
            body.style.removeProperty('overflow');
            body.style.removeProperty('margin-right');
            window.scrollTo(0, 0);
        }
    }
}
