export interface AlertTypeMap {
    [key: string]: {
        imgClass: string;
        status: string;
        titleColor: string;
        textColor: string;
    };
}

export class AlertTypeUtility {
    private static alertTypeMap: AlertTypeMap = {
        PAYMENT: {
            imgClass: 'status-error-icon',
            status: 'error',
            titleColor: 'red',
            textColor: 'black'
        },
        INFORMATION: {
            imgClass: 'status-info-icon',
            status: 'information',
            titleColor: 'black',
            textColor: 'black'
        },
        REFUND: {
            imgClass: 'status-info-icon',
            status: 'information',
            titleColor: 'black',
            textColor: 'black'
        },
        REPLACEMENT: {
            imgClass: 'status-info-icon',
            status: 'information',
            titleColor: 'black',
            textColor: 'black'
        },
        CANCEL: {
            imgClass: 'status-info-icon',
            status: 'information',
            titleColor: 'black',
            textColor: 'black'
        },
        REVERSAL: {
            imgClass: 'status-info-icon',
            status: 'information',
            titleColor: 'black',
            textColor: 'black'
        },
        PAYMENT_METHOD_UPDATE: {
            imgClass: '',
            status: 'success',
            titleColor: 'link',
            textColor: 'black'
        }
    };

    public static getAlertTypeMapValues(alertType: 'PAYMENT' | 'REFUND' | 'REPLACEMENT' | 'CANCEL' | 'REVERSAL' | 'PAYMENT_METHOD_UPDATE') {
        return AlertTypeUtility.alertTypeMap[alertType] || AlertTypeUtility.alertTypeMap.INFORMATION;
    }
}