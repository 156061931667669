import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';

interface ChargeLineProps {
    chargeAmount: string;
    insuranceLine?: string;
    priceSize?: 'base' | 'small' | 'large';
}

/**
 * show charge amount and insurance line
 */
class MedicationCardChargeLine extends Component<ChargeLineProps, {}> {
    render() {
        const decimalSeparator = '.'
        const { insuranceLine, chargeAmount } = this.props;
        const [currencySymbol, priceAmount] = [chargeAmount.slice(0, 1), chargeAmount.slice(1)];
        const [wholeValue, fractionalValue] = priceAmount.split(decimalSeparator)

        return (
            <pui-stack-item overflow="true" spacingTop="small">
                <pui-stack
                    mainAxisArrangement="start"
                    secondaryaxisarrangement="start"
                    spacingBottom="small"
                    >
                    <pui-price
                      priceSize={this.props.priceSize}
                      currencySymbol={currencySymbol}
                      wholeValue={wholeValue}
                      fractionalValue={fractionalValue}
                      decimalSeparator={decimalSeparator}
                    />
                    {insuranceLine &&
                        <pui-stack-item overflow="true">
                            <pui-text
                                input={insuranceLine}
                                textSize="small"
                            />
                        </pui-stack-item>}
                </pui-stack>
            </pui-stack-item>
        );
    }
}

MedicationCardChargeLine.contextType = I18NContext;

export default MedicationCardChargeLine;