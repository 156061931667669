import React, { FunctionComponent } from 'react';
import PaymentBottomSheet from './PaymentBottomSheet';
import { PaymentLabels } from '../component';

interface PaymentDeclineAlertBottomSheetProps {
    paymentLabels: PaymentLabels;
    gqlOrderId: string;
    paymentDeclineAlertTextOne: string;
    paymentDeclineAlertIngress: string;
    paymentDeclineAlertTextTwo: string;
}

const PaymentDeclineAlertBottomSheet: FunctionComponent<PaymentDeclineAlertBottomSheetProps> = (props) => {
    const {
        paymentLabels,
        gqlOrderId,
        paymentDeclineAlertTextOne,
        paymentDeclineAlertIngress,
        paymentDeclineAlertTextTwo
    } = props;

    return (
        <>
            <pui-text inline input={paymentDeclineAlertTextOne} />
            <pui-bottom-sheet
                id="payment-decline-modal"
                data-csa-c-content-id="payment-decline-modal"
                data-csa-c-type="popup"
                data-csa-c-slot-id="order-detail"
                inline
                closeable
                text={paymentDeclineAlertIngress}
                textSize="medium"
            >
                <PaymentBottomSheet
                    addPaymentTitle={paymentLabels.addPaymentMethodTitle}
                    addPaymentText={paymentLabels.addPaymentMethodText}
                    gqlOrderId={gqlOrderId}
                />
            </pui-bottom-sheet>
            <pui-text inline input={paymentDeclineAlertTextTwo} />
        </>
    );
};

export default PaymentDeclineAlertBottomSheet;