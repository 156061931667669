import React from 'react';
import { RefundAmount } from '../component';
import { formatCurrency, formatReturnAmount } from '../common/FormatUtils';
import { getLocale } from '../../Utils'

interface RefundComponentRowProp {
    amount?: RefundAmount,
    nameLabel?: string,
    fontWeight?: string,
    noDivider?: boolean, // by default is false
    spacingBottom?:'small'|'medium'|'large'
}

class RefundComponentRow extends React.Component<RefundComponentRowProp, {}> {

    render() {

        if (!this.props.amount) {
            return (<></>);
        }

        return (
            <React.Fragment>
                <pui-stack direction='horizontal' spacingBottom={this.props.spacingBottom}>
                    <pui-stack-item>
                        <pui-text fontWeight={this.props.fontWeight ? this.props.fontWeight : ""} input={this.props.nameLabel + ": "} textSize='medium' textAlign="left" />
                    </pui-stack-item>
                    <pui-stack-item>
                        <pui-text fontWeight={this.props.fontWeight ? this.props.fontWeight : ""} input={formatCurrency(formatReturnAmount(this.props.amount.amount), this.props.amount.currency, getLocale())} textSize='medium' textAlign="right" />
                    </pui-stack-item>
                </pui-stack>
                {
                    this.props.noDivider ? <></> : <pui-divider spacingTop='small' spacingBottom='small' />
                }

            </React.Fragment>
        )
    }
}

export default RefundComponentRow;