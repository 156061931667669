import React, { FunctionComponent } from 'react';
import { Alert, PaymentLabels } from '../component';
import PaymentDeclineAlertBottomSheet from './PaymentDeclineAlertBottomSheet';
import { CsaTagProps } from "../csa/utils";

interface PaymentDeclineAlertProps {
    paymentLabels: PaymentLabels;
    alert?: Alert;
    gqlOrderId: string;
    csaTagProps?: CsaTagProps
}

export const PaymentDeclineAlert: FunctionComponent<PaymentDeclineAlertProps> = (props) => {
    const subline = props.alert?.article?.sublines?.[0];
    const [paymentDeclineAlertTextOne, paymentDeclineAlertIngress, paymentDeclineAlertTextTwo] = subline
      ? subline.split('%')
      : props.paymentLabels.paymentDeclineAlertText.split('%');
    const title = props.alert?.article?.headline || props.paymentLabels.paymentDeclineAlertTitle;

    return (
      <pui-banner status="error" padding="med-small" spacingTop="small" spacingBottom="small"
                  data-csa-c-content-id={props.csaTagProps?.contentId}
                  data-csa-c-type={props.csaTagProps?.type}
                  data-csa-c-slot-id={props.csaTagProps?.slotId}>
            <pui-stack direction="vertical" paddingRight="20px">
                <pui-heading spacingBottom="small" textSize="small" textcolor="red" input={title}></pui-heading>
                <pui-stack-item overflow="true">
                    <PaymentDeclineAlertBottomSheet
                        paymentLabels={props.paymentLabels}
                        gqlOrderId={props.gqlOrderId}
                        paymentDeclineAlertTextOne={paymentDeclineAlertTextOne}
                        paymentDeclineAlertIngress={paymentDeclineAlertIngress}
                        paymentDeclineAlertTextTwo={paymentDeclineAlertTextTwo}
                    />
                </pui-stack-item>
            </pui-stack>
        </pui-banner>
    );
};

export default PaymentDeclineAlert;