import React from 'react';
import {Annotation, Item, PurchasedItemSummary} from '../component';
import MedicationCard, {CardStyle} from './MedicationCard';
import ItemAnnotations from './ItemAnnotations';

interface ItemMedicationSummaryCardProps {
    item?: Item;
    purchaseItemSummary?: PurchasedItemSummary;
    children?: any;
    autoRefillCardLine?: JSX.Element;
    showSupply?: boolean;
    cardStyle?: CardStyle;
    isPillPack?: boolean;
    medStatus?: string;
    showAnnotations?: boolean;
}

/**
 * The component is used in HistoryPage, TrackerPage, and OrderSummaryPage
 * It is used display the MedicationCard in different pages based on the props passed from those pages..
 * @param props
 */
export default (props: ItemMedicationSummaryCardProps) => {
    const { item, purchaseItemSummary, autoRefillCardLine,showAnnotations=true, showSupply = true, cardStyle = CardStyle.HEADLINE_LARGE } = props;

    let crossedOut = false;

    const ignoredAnnotations = new Set();
    ignoredAnnotations.add("UpdatePaymentMethod")
    ignoredAnnotations.add("Cancel")

    let annotations: Annotation[] = item?.annotations || purchaseItemSummary?.annotations || [];
    annotations = annotations.filter(annotation => !ignoredAnnotations.has(annotation.annotationType));
    annotations.forEach(annotation => {
        if (annotation.annotationType === 'ReplacedBy') crossedOut = true;;
    });
    
    const imageUrl = item?.rx.imageUrl || purchaseItemSummary?.rxInfo?.imageUrl;
    const headline = item?.rx.headline || purchaseItemSummary?.heading.headline || '';
    let subline = item?.rx.subline || purchaseItemSummary?.heading.subline;
    /**
     * For OrderSummary Page, If it is medication in the PillPack
     * The Medication in the Accordion will shows that the item charge and insurance Line in subLine
     * Todo: check how this will Perform
     */
    if (props.isPillPack && item) {
        subline = `${item?.charges ? item.charges?.grandTotal + ' ' : ''}${item?.insurances[0] ? item.insurances[0]?.compositeTailLabel : ''}`
    }

    const annotationsTextColor = cardStyle === CardStyle.HEADLINE_MED_50PX_IMG ? "black" : undefined;
    return <MedicationCard headline={headline} subline={subline} imageURL={imageUrl} crossedOut={crossedOut}
                           autoRefillCardLine={autoRefillCardLine} showSupply={showSupply} cardStyle={cardStyle}
                           purchaseId={purchaseItemSummary?.purchaseId} orderId={purchaseItemSummary?.orderId}
                           medStatus={props.medStatus} isPillPack={props.isPillPack}>
                {showAnnotations===true && <ItemAnnotations annotations={annotations} textSize="small" textColor={annotationsTextColor}/>}
                {props.children}
        </MedicationCard>
}
