import {Annotation, Order, Transaction} from "../component";

export const hasPaymentFixupOrder = (orders: Order[]): boolean => {
    return orders.some(order => isPaymentFixUp(order.transactions));
}

export const isPaymentFixUp = (transactions: Transaction[]): boolean => {
    let paymentFixUp = false;

    if (transactions && transactions.length > 0) {
        transactions.forEach(transaction => {
            transaction?.annotations.forEach(annotation => {
                if (annotation.annotationType === "PayFixup") {
                    paymentFixUp = true;
                }
            });
        });
    }
    return paymentFixUp;
}

export const isBackupPaymentMethodUsed = (transaction: Transaction): boolean => {
    return transaction.originalPaymentMethod != null;
}

export const suppressPayFixup = (annotations: Annotation[]) => {
    return hasAnnotation(annotations, "SuppressPayFixup")
}

export const canUpdate = (annotations: Annotation[]) => {
    return hasAnnotation(annotations, "UpdatePaymentMethod") || hasAnnotation(annotations, "PayFixup")
}

export const needFixup = (annotations: Annotation[]) => {
    return hasAnnotation(annotations, "PayFixup")
}

export const backupPaymentMethodUsed = (annotations: Annotation[]) => {
    return hasAnnotation(annotations, "BackupPaymentMethod")
}

export const hasAnnotation = (annotations: Annotation[] | null, annotationType: string): boolean => {
    // If annotations is null or an empty array, return false
    if (!annotations || annotations.length === 0) {
        return false;
    }

    // Otherwise, check if at least one annotation has the specified annotationType
    return annotations.some(annotation => annotation.annotationType === annotationType);
};