import React from "react";
import { CsaTagProps } from "../csa/utils";

export interface MedicationGroupAccordionAlertMessageProps {
  type: string | 'info' | 'error' | 'success'
  message: string;
  href?: string;
  csaTagProps?: CsaTagProps
}

/**
 * Shows an alert message with a status icon.
 * Message is split into two parts, the first part is displayed as is, the second part is displayed as a link.
 * The link href is provided by the caller.
 * @param props
 * @constructor
 */
export default function MedicationGroupAccordionAlertMessage(props: MedicationGroupAccordionAlertMessageProps) {
  const icon = <pui-icon inline imgClass={`status-${props.type}-icon`} spacingRight="small" height='18px' width='18px' style={{ paddingTop: '2px'  }}/>
  const [message, linkMessage] = props.message.split("%")

  const textColor = props.type === "error" ? "red" : props.type === "success" ? "link" : 'black';
  return (<pui-stack
    style={{textDecoration: "none"}}
    direction="horizontal"
    mainAxisArrangement="start"
    secondaryAxisArrangement="start"
    spacingTop={"small"}
    data-csa-c-content-id={props.csaTagProps?.contentId}
    data-csa-c-type={props.csaTagProps?.type}
    data-csa-c-slot-id={props.csaTagProps?.slotId}
  >
    {icon}
    <pui-text input={message} inline textColor={textColor}/>
    {linkMessage && <pui-link text={linkMessage} inline href={props.href}/>}
  </pui-stack>)
}