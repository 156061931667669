import React from 'react';
import { unlockPageScroll } from '../../components/common/ScrollUtility';

interface OrderHistoryRedirectionComponentProp {
    isRedirected: Boolean;
    redirectionMessage?: String;
    redirectionTitle?: String;
}

class OrderHistoryRedirectionComponentRow extends React.Component<OrderHistoryRedirectionComponentProp, {}> {

    render() {
        if (this.props.isRedirected) {
            unlockPageScroll();

            return (
                <pui-banner status="success" spacingBottom="medium" paddingRight="medium" paddingLeft="medium" style={{"padding-top":"14px","padding-bottom":"14px"}}>
                    <pui-heading
                        textsize="mini"
                        input={this.props.redirectionTitle}
                        textColor="link"
                        fontWeight="700"
                        style={{ "font-size": '14px' }}
                    ></pui-heading>
                    <pui-text spacingTop="mini" input={this.props.redirectionMessage}> </pui-text>
                </pui-banner>
            )
        }

        return (<></>);
    }
}

export default OrderHistoryRedirectionComponentRow;