import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';
import MedicationCardPromotionLine from './MedicationCardPromotionLine';
import { Annotation } from '../component';
import { generateAnnotationLinePillpack } from './ItemAnnotations';

// Derived from https://code.amazon.com/packages/ApexDashboardHZAVingilot/blobs/mainline/--/webapp/WEB-INF/tags/pyo/rx-card.tag

export enum CardStyle {
    HEADLINE_LARGE,
    HEADLINE_MED_50PX_IMG
}

export enum MedStatus {
    REFUNDED = 'Refunded',
    CANCELLED = 'Cancelled'
}

interface MedicationCardProps {
    headline: string;
    subline?: string;
    promotionSavingLine?: string;
    imageURL?: string;
    crossedOut?: boolean;
    autoRefillCardLine?: JSX.Element;
    showSupply?: boolean;
    cardStyle?: CardStyle;
    purchaseId?: string;
    orderId?: string;
    medStatus?: string;
    isPillPack?: boolean;
    annotations?: Annotation[];
}

/**
 * Reusable Component to show medication data
 */
class MedicationCard extends Component<MedicationCardProps, {}> {
    render() {
        const { headline, subline, imageURL, crossedOut, autoRefillCardLine, showSupply=true,
            cardStyle=CardStyle.HEADLINE_LARGE, promotionSavingLine } = this.props;
        const styling = crossedOut ? { textDecorationLine: 'line-through' } : {};
        const imgSize = cardStyle === CardStyle.HEADLINE_MED_50PX_IMG ? "50px" : "48px";
        const detailsSpacingLeft = cardStyle === CardStyle.HEADLINE_LARGE ? "medium" : "med-small";
        /**
         * if It isPillPack is true, then the medication card should use the iconClass
         * It is used for TrackerPage case.
         */
        
        return (<pui-product-card
            data-purchase-id={this.props.purchaseId}
            data-order-id={this.props.orderId}
            leftsideimgsrc={imageURL}
            leftsideimgheight={imgSize}
            leftsideimgwidth={imgSize}
            leftsideiconclass={this.props.isPillPack ? 'pillpack-icon' : imageURL ? '' : 'rx-bottle-icon'}
            noborder="true"
            noshadow="true"
            nospacing="true"
            nopadding="true"
            spacingTop="medium"
          >
            <pui-section spacingLeft={detailsSpacingLeft}>
            <pui-stack direction="vertical" overflow="true" style={{ marginLeft: '10px!' }}>
                <pui-stack-item overflow="true" style={{ paddingTop: '3px' }}>
                    {this.props.annotations && (
                            <pui-stack direction="vertical" mainAxisArrangement="start">
                                {this.props.annotations.map((annotation) => (
                                    <React.Fragment key={annotation.label}>
                                        {generateAnnotationLinePillpack(annotation, cardStyle === CardStyle.HEADLINE_LARGE ? "large" : "medium", 'black')}
                                    </React.Fragment>
                                ))}
                            </pui-stack>
                    )}
                    <pui-text
                        style={styling}
                        data-ref="headline"
                        textSize={cardStyle === CardStyle.HEADLINE_LARGE ? "large" : "medium"}
                        fontweight="bold"
                        input={headline}
                    />
                </pui-stack-item>
                {showSupply && this._generateSupply(styling, subline)}
                {promotionSavingLine && (
                    <MedicationCardPromotionLine promotionSavingsLine={promotionSavingLine} />
                )}
                {autoRefillCardLine}
                <pui-stack-item overflow="true">
                    {this.props.children}
                </pui-stack-item>
            </pui-stack>
            </pui-section>
          </pui-product-card>
        );
    }

    private _generateSupply(styling: { textDecorationLine: string } | {}, subline: string | undefined) {
        return <pui-stack-item overflow="true">
            <pui-text spacingTop="mini"
                textSize='small'
                style={styling}
                data-ref="rx-supply-label"
                input={subline}/>
        </pui-stack-item>;
    }
}

MedicationCard.contextType = I18NContext;

export default MedicationCard;