import React, {useState} from 'react';
import DispenseStatementFilterOptions from "./DispenseStatementFilterOptions";
import {DispenseStatementFilterOption, DispenseStatementPageLabels} from "../component";
import {AbortDownload, StatementStatus} from "./DispenseStatementBottomSheet";
import fetchDispenseStatement from "../../accessors/DispenseStatementAccessor";

const downloadTimeout = 60000;
const statementYearRegex = /%statementYear%/s;

declare global {
    interface Window {
        webkit: any;
        pharmacyBridge: any;
    }
}

interface DispenseStatementBottomSheetContentProps {
    labels: DispenseStatementPageLabels,
    filterOptions: DispenseStatementFilterOption[],
    showBottomSheet(): void;
    hideBottomSheet(): void;
    abortDownload: AbortDownload;
    setStatementStatus(status: StatementStatus): void;
    statementStatus: StatementStatus;
    iosDevice ?: boolean;
    androidDevice ?: boolean;
    mShop ?: boolean;
}

class FetchReportError extends Error {
    filterOptionValue: string;

    constructor(name: string, filterOptionValue: string, message?: string) {
        super(message);
        this.name = name;
        this.filterOptionValue = filterOptionValue;
        Object.setPrototypeOf(this, FetchReportError.prototype);
    }
}

const promptDownload = (response: Blob, fileName: string) => {
    const fileUrl = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

function DispenseStatementBottomSheetContent(props: DispenseStatementBottomSheetContentProps) {
    const [downloadStatementError, setDownloadStatementError] = useState<FetchReportError>();

    const fileName = `${props.labels.medicationStatements}_`;

    const abortDownload = (reason: string) => {
        if (props.abortDownload.abortController) {
            props.abortDownload.reason = reason;
            props.abortDownload.abortController.abort();
        }
    }

    const lookupFilterOption = (filterOptionValue: string) => {
        return props.filterOptions.find((filterOption) => filterOption.value === filterOptionValue);
    }

    const fetchStatement = async (filterOptionValue: string) : Promise<Blob> => {
        props.abortDownload.abortController = new AbortController();
        props.abortDownload.reason = undefined;

        const filterOption = lookupFilterOption(filterOptionValue);
        const {signal} = props.abortDownload.abortController;
        setTimeout(() => {
            abortDownload("timeout");
        }, downloadTimeout)

        const filterOptionList = filterOption ? filterOption.parameterList.map((parameter) => [parameter.key, parameter.value]) : []
        const filterParams = new URLSearchParams(filterOptionList);
        let rejected = false;
        return new Promise((resolve, reject) => {
            return fetchDispenseStatement(filterParams, {signal})
                .then(r => {
                    if (r.status === 404 || r.status === 204) {
                        rejected = true;
                        reject(new FetchReportError("no-statement", filterOptionValue,
                            props.labels.noStatementError.replace(statementYearRegex, filterOptionValue)));
                    } else if (r.type === "opaqueredirect") {
                        rejected = true;
                        window.location.reload();
                        reject(new FetchReportError("generation", filterOptionValue, undefined));
                    } else if (r.status !== 200) {
                        rejected = true;
                        reject(new FetchReportError("generation", filterOptionValue, undefined));
                    }
                    return r.blob();
                })
                .then(blob => {
                    if (props.mShop && !rejected) {
                        return new Promise((resolve, _) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.readAsDataURL(blob);
                        });
                    } else {
                        resolve(blob);
                    }
                })
                .then(data => {
                    if (!rejected && props.mShop && props.iosDevice && doesWindowHavePostMessage()) {
                        props.setStatementStatus(StatementStatus.Done);
                        iosWriteFile('fileWriter', data, fileName + lookupFilterOption(filterOptionValue)?.value);
                    }
                    else if (!rejected && props.mShop && props.androidDevice && doesWindowHavePharmacyBridge()) {
                        props.setStatementStatus(StatementStatus.Done);
                        androidWriteFile(data, fileName + lookupFilterOption(filterOptionValue)?.value);
                    }
                })
                .catch(error => {
                    if(error.name === "AbortError" && props.abortDownload.reason === "hide") {
                        reject(new FetchReportError("hide", filterOptionValue, undefined));
                    } else {
                        reject(new FetchReportError("generation", filterOptionValue, undefined));
                    }
                });
        });
    }

    const androidWriteFile = (data: any, fileName: string) => {
        window.pharmacyBridge.fileWriter(data, fileName);
    }

    const iosWriteFile = (action: string, data: any, fileName: string) => {
        window.webkit.messageHandlers.mshopwolfgang.postMessage({
            action: action,
            data: data,
            fileName: fileName
        });
    }

    const doesWindowHavePharmacyBridge = (): boolean => {
        return (typeof window === 'object')
        && (typeof window.pharmacyBridge === 'object');
    }

    const doesWindowHavePostMessage = (): boolean => {
        return (typeof window === 'object')
          && (typeof window.webkit === 'object')
          && (typeof window.webkit.messageHandlers === 'object')
          && (typeof window.webkit.messageHandlers.mshopwolfgang === 'object')
          && (typeof window.webkit.messageHandlers.mshopwolfgang.postMessage === 'function');
    };

    const handleDownloadClick = async (filterOptionValue: string) => {
        props.setStatementStatus(StatementStatus.Downloading);
        try {
            const response = await fetchStatement(filterOptionValue);
            if (props.iosDevice) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            if (!props.mShop) {
                promptDownload(response, fileName + lookupFilterOption(filterOptionValue)?.value + '.pdf');
                props.setStatementStatus(StatementStatus.Done)
            }
        } catch (error) {
            if (error instanceof FetchReportError) {
                switch (error.name){
                    case "generation": {
                        setDownloadStatementError(error as FetchReportError);
                        props.setStatementStatus(StatementStatus.GenerationError);
                        break;
                    }
                    case "no-statement": {
                        setDownloadStatementError(error as FetchReportError);
                        props.setStatementStatus(StatementStatus.NoStatementError);
                        break;
                    }
                    case "hide": {
                        break;
                    }
                    default: {
                        setDownloadStatementError(error as FetchReportError);
                        props.setStatementStatus(StatementStatus.GenerationError);
                        break;
                    }
                }
            } else {
                // Handle cases where error is not an instance of Error
                console.error("Unknown error type:", error);
            }

        }
    }

    const handleDownloadAgainClick = async () => {
        if (!downloadStatementError) return;
        await handleDownloadClick(downloadStatementError.filterOptionValue);
    }

    const generateSection = (status: StatementStatus) => {
        return (
            <>
                {status === StatementStatus.FilterOptions &&
                    <DispenseStatementFilterOptions
                        onDownloadClick={handleDownloadClick}
                        labels={props.labels}
                        filterOptions={props.filterOptions}
                        showChooseYear={true}
                    />
                }
                {status === StatementStatus.Downloading &&
                    <pui-section direction="vertical" >
                        <pui-section-column spacing="large" style={{height: "24px"}}>
                            <pui-loading-indicator style={{position: 'relative'}}/>
                        </pui-section-column>
                        <pui-section-column overflow="true">
                            <pui-text spacingBottom="medium" input={props.labels.downloadProgress}/>
                        </pui-section-column>
                    </pui-section>
                }
                {status === StatementStatus.Done && <>
                    <pui-section flowdirection="vertical" >
                        <pui-section-column spacing="large">
                            <div className="pui-icon status-success-icon" style={{display: "flex", justifyContent: "center", height: "32px", width: "32px"}}/>
                        </pui-section-column>
                        <pui-section-column>
                            <pui-text spacingBottom="medium" input={props.labels.downloadSuccess}/>
                        </pui-section-column>
                        <pui-section-column>
                            <pui-button
                                id="button-to-confirm-dispense-statement-download"
                                data-csa-c-type="button"
                                data-csa-c-action="confirm"
                                data-csa-c-content-id="button-to-confirm-dispense-statement-download"
                                data-csa-c-slot-id="dispense-statement"
                                label={props.labels.continueButton}
                                spacingTop="small"
                                textColor="black-color"
                                textSize="extra-large"
                                onClick={props.hideBottomSheet}
                            />
                        </pui-section-column>
                    </pui-section>
                </>
                }
                {status === StatementStatus.GenerationError && <>
                    <pui-section flowdirection="vertical" >
                        <pui-section-column spacing="large">
                            <div className="pui-icon status-error-icon" style={{display: "flex", justifyContent: "center", height: "32px", width: "32px"}}/>
                        </pui-section-column>
                        <pui-section-column>
                            <pui-text spacingBottom="medium" input={props.labels.downloadError}/>
                        </pui-section-column>
                        <pui-section-column>
                            <pui-button
                                id="button-to-confirm-dispense-statement-error"
                                data-csa-c-type="button"
                                data-csa-c-action="confirm"
                                data-csa-c-content-id="button-to-confirm-dispense-statement-error"
                                data-csa-c-slot-id="dispense-statement"
                                label={props.labels.downloadAgain}
                                spacingTop="small"
                                textColor="black-color"
                                textSize="extra-large"
                                onClick={handleDownloadAgainClick}
                            />
                        </pui-section-column>
                    </pui-section>
                </>
                }
                {status === StatementStatus.NoStatementError && <>
                    <DispenseStatementFilterOptions
                        labels={props.labels}
                        filterOptions={props.filterOptions}
                        onDownloadClick={handleDownloadClick}
                        alertMessage={downloadStatementError?.message}
                        hideFilterOption={downloadStatementError?.filterOptionValue}
                        showChooseYear={false}
                    />
                </>
                }
            </>
        );
    };
    return (<>
        <pui-section>
            <pui-heading
                input={props.labels.medicationStatements}
                textSize="medium"
                spacingTop="small"
                spacingBottom="medium"
            />
            {generateSection(props.statementStatus)}
        </pui-section>
    </>);
}

export default DispenseStatementBottomSheetContent;