import React from 'react';
import { I18NContext } from '../../I18NContext';
import CancelFormV2 from './CancelFormV2';
import {CancelBottomSheetDetails, OrderSummaryPageLabels} from '../component';
import "./Common.css";
import ErrorBoundary from '../../ErrorBoundary';

interface CancelV2Props {
    orderSummaryPageLabels: OrderSummaryPageLabels;
    cancelDetails: CancelBottomSheetDetails;
    refresh?: () => void;
}

interface CancelV2State {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
}

class CancelV2 extends React.Component<CancelV2Props, CancelV2State> {

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent("DOMContentLoaded"));
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        if (this.state.bottomSheet && this.state.bottomSheet.current) {
            this.state.bottomSheet.current?.show();
        }
    }

    render() {
        return (<>
            <pui-stack
                direction="vertical"
                ref={this.state.button}
                onClick={(e: any) => this._openBottomSheet(e)}
                class="clickable"
            >
                <pui-stack-item-button buttonPadding="none">
                    <pui-text
                        input={this.props.orderSummaryPageLabels.cancelForm.cancelItemInThisOrder}
                        textSize="medium"
                        color="black"
                        paddingTop="medium"
                        paddingBottom="medium"
                    />
                </pui-stack-item-button>
            </pui-stack>
            <ErrorBoundary>
                <pui-bottom-sheet
                    id="cancellation-modal"
                    data-csa-c-content-id="cancellation-modal"
                    data-csa-c-type="popup"
                    data-csa-c-slot-id="order-detail"
                    hideLink
                    ref={this.state.bottomSheet}
                >
                    <CancelFormV2
                        cancelDetails={this.props.cancelDetails}
                        orderSummaryPageLabels={this.props.orderSummaryPageLabels}
                        refresh={this.props.refresh}
                    />
                </pui-bottom-sheet>
            </ErrorBoundary>
        </>)
    }
}

CancelV2.contextType = I18NContext;

export default CancelV2;