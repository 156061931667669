import React from 'react';
import { GroupType } from "../../common/Types";
import PillPackMedicationGroupAccordionCard from "../PillPackMedicationGroupAccordionCard";
import { filterAnnotations, generateMedSyncAnnotationAlert } from "./AnnotationUtils";
import { ItemGroup, ItemGroupSummary } from "../component";
import { ForwardTextLink } from "./ForwardTextLink";
import ItemAnnotations from "./ItemAnnotations";
import ItemMedicationSummaryCard from "./ItemMedicationSummaryCard";
import { CardStyle } from "./MedicationCard";
import MedicationCardAutoRefillLine from "./MedicationCardAutoRefillLine";
import MedicationGroupAccordionAlertMessage from "../medicationGroup/MedicationGroupAccordionAlertMessage";
import {
    CsaTagProps,
    generateUrlWithRefTag,
    hasBottleOrPackets,
    PILL_PACK_REF_TAGS,
    REF_TAGS
} from "../csa/utils";

interface DeliveryCardProps {
    itemGroupSummary: ItemGroupSummary;
    trackingLinkLabel: string;
    orderDetailsLinkLabel: string;
    bypassTracker: boolean;
    csaTagProps?: CsaTagProps;
}

export default function DeliveryCard({itemGroupSummary, bypassTracker, csaTagProps}: DeliveryCardProps) {

    //TODO: Revisit after QA handoff for to investigate an ideal long term fix
    const getOrderPageUrl = (itemGroupSummary: ItemGroupSummary) => {
        if (itemGroupSummary.itemGroups.length > 0) {
            return `/order/${itemGroupSummary.itemGroups[0].id}`;
        } else if (itemGroupSummary.pillPackGroupSummary != null) {
            return `/order/${itemGroupSummary.pillPackGroupSummary.id}`;
        } else {
            return `/order/${itemGroupSummary.bottleGroupSummary.id}`;
        }
    }

    let nextPageUrl: string;
    if (bypassTracker) {
        nextPageUrl = getOrderPageUrl(itemGroupSummary); // todo update the nextPageUrl for case otherItemGroups is empty
        nextPageUrl = generateUrlWithRefTag(nextPageUrl, hasBottleOrPackets(itemGroupSummary) ?
          PILL_PACK_REF_TAGS.DELIVERY_CARD_TO_SUMMARY_PAGE : REF_TAGS.DELIVERY_CARD_TO_SUMMARY_PAGE)
    } else {
        nextPageUrl = `/progress-tracker/${itemGroupSummary.id}`;
        nextPageUrl = generateUrlWithRefTag(nextPageUrl, hasBottleOrPackets(itemGroupSummary) ?
          PILL_PACK_REF_TAGS.DELIVERY_CARD_TO_TRACKER_PAGE : REF_TAGS.DELIVERY_CARD_TO_TRACKER_PAGE)
    }

    const generateHeader = () => {
        return (
            <pui-stack direction="horizontal" secondaryAxisArrangement="start">
                <pui-heading input={itemGroupSummary.headlineAbbr} headingLevel="3" textSize="small"/>
            </pui-stack>
        );
    }

    const ignoredAnnotationsForSummaries = new Set<string>(["UpdatePaymentMethod", "Cancel", "ItemCancelledOn", "Replaces", "ReplacedBy"]);
    const ignoredAnnotationsForGroups = new Set<string>(["UpdatePaymentMethod", "Cancel", "ItemCancelledOn", "PayFixup"]);

    /**
     * Priority Order for showing alert for PillPack Accordion Card
     *     1. Payment decline
     *     2. Cancellation(partial) Refund can be shown together.
     *     3. med sync
     */
    const generateGroupAlertComponent = () => {
        const pillPackAnnotations = itemGroupSummary?.pillPackGroupSummary?.annotations || [];
        const bottleAnnotations = itemGroupSummary?.bottleGroupSummary?.annotations || [];
        const itemGroupSummaryAnnotations = itemGroupSummary?.annotations || []
        const uniqueAnnotations = new Set();
        const annotations = [...pillPackAnnotations, ...bottleAnnotations, ...itemGroupSummaryAnnotations]
            .filter(annotation => {
                const isDuplicate = uniqueAnnotations.has(annotation.annotationType);
                uniqueAnnotations.add(annotation.annotationType);
                return !isDuplicate;
            })
        const otherPillPackAlertType = new Set<string>(["PillPackPartialCancel", "Refund"]);
        const otherPillPackAnnotations = annotations.filter((annotation) => otherPillPackAlertType.has(annotation.annotationType))
        const paymentDeclineAnnotation = annotations.filter(annotation =>
            annotation.annotationType === "PayFixup");

        if (paymentDeclineAnnotation.length > 0) {
            return <MedicationGroupAccordionAlertMessage type={"error"} message={paymentDeclineAnnotation[0].label}
                                                         key={"pillpack-payment-decline-alert"}/>
        }
        if (otherPillPackAnnotations.length > 0) {
            return <>
                {otherPillPackAnnotations.map((annotation) =>
                    <MedicationGroupAccordionAlertMessage type={"info"}
                                                          message={annotation.label}
                                                          key={`${annotation.annotationType}-alert`}/>)}
            </>
        }
        const medSyncAnnotationAlertMessage = generateMedSyncAnnotationAlert(itemGroupSummaryAnnotations);
        return <>
            {medSyncAnnotationAlertMessage &&
              <MedicationGroupAccordionAlertMessage {...medSyncAnnotationAlertMessage}
                                                    key={"med-sync-alert"}
                                                    csaTagProps={{contentId:"med-sync",slotId:"med-sync",type:"alert"}}
              />}
        </>
    }

    /**
     * @param itemGroup
     * itemGroup.itemSummaries contains the items in the same order
     * @param itemGroupType
     */
    const generateItemGroup = (itemGroup: ItemGroup, itemGroupType?: string, showAnnotations?: boolean) => {
        const keyPostfix = itemGroupType ? `-${itemGroupType}-orderhistorymedcard` : '-orderhistorymedcard';
        return itemGroup.itemSummaries.map((itemSummary, i) => {
            const RefillCardLine = <MedicationCardAutoRefillLine
                isAutoRefill={itemSummary.isAutoRefill}
                autoRefillTextDisplay={itemGroupSummary.orderingChannelLine}/>;

            itemSummary.annotations = filterAnnotations(itemSummary.annotations, ignoredAnnotationsForSummaries);

            return  <ItemMedicationSummaryCard
                purchaseItemSummary={itemSummary}
                autoRefillCardLine={RefillCardLine}
                showSupply={true}
                cardStyle={CardStyle.HEADLINE_MED_50PX_IMG}
                showAnnotations={showAnnotations === false ? showAnnotations : true}
                key={i + keyPostfix}/>;
        });
    }
    /**
     * generate PillPackMedicationGroupAccordionCard
     */
    const generatePillPackMedicationGroupAccordionCard = () => {
        const pillPackItemGroups = itemGroupSummary.pillPackGroupSummary?.itemGroups;
        const pillPackBottleItemGroups = itemGroupSummary.bottleGroupSummary?.itemGroups;
        const pillPackMedicationCards = pillPackItemGroups?.length > 0 ? pillPackItemGroups.map((itemGroup) =>
            generateItemGroup(itemGroup, 'pillpack', false)
        ) : undefined
        const bottleMedicationCards = pillPackBottleItemGroups?.length > 0 ? pillPackBottleItemGroups.map((itemGroup) =>
            generateItemGroup(itemGroup, 'bottle', false)
        ) : undefined
        const headerProps = {
            pillPack: itemGroupSummary.pillPackGroupSummary ? {
                heading: itemGroupSummary.pillPackGroupSummary.headline,
                subHeading: itemGroupSummary.pillPackGroupSummary.subline,
                groupType: GroupType.PILLPACK
            } : undefined,
            bottle: itemGroupSummary.bottleGroupSummary ? {
                heading: itemGroupSummary.bottleGroupSummary.headline,
                subHeading: itemGroupSummary.bottleGroupSummary.subline,
                groupType: GroupType.BOTTLE
            } : undefined
        }
        return <PillPackMedicationGroupAccordionCard
            id={itemGroupSummary.id}
            key={`accordion-${itemGroupSummary.id}`}
            nextPageUrl={nextPageUrl}
            medicationCards={{pillPack: pillPackMedicationCards, bottle: bottleMedicationCards}}
            showDivider={itemGroupSummary.itemGroups.length > 0}
            headerProps={headerProps}
            groupAlertComponent={generateGroupAlertComponent()}/>
    }


    return(
      <pui-product-card noshadow="true" key={itemGroupSummary.id} spacingBottom="med-small" paddingTop="med-small"
                        paddingLeft="mini" paddingRight="med-small"
                        data-csa-c-content-id={csaTagProps?.contentId}
                        data-csa-c-type={csaTagProps?.type}
                        data-csa-c-slot-id={csaTagProps?.slotId}>
        <ForwardTextLink url={nextPageUrl} key={`header-forward-${itemGroupSummary.id}`}>
            {itemGroupSummary.headline && generateHeader()}
        </ForwardTextLink>
        {generatePillPackMedicationGroupAccordionCard()}
        <ForwardTextLink url={nextPageUrl} key={`other-group-forward-${itemGroupSummary.id}`}>
            {itemGroupSummary.itemGroups.map((itemGroup) => <React.Fragment key={itemGroup.id}>
                    {generateItemGroup(itemGroup)}
                    {(itemGroup.annotations.length > 0) && <pui-stack direction="horizontal">
                        <ItemAnnotations annotations={filterAnnotations(itemGroup.annotations, ignoredAnnotationsForGroups)} textSize="large" textColor="black"/>
                    </pui-stack>}
                </React.Fragment>
            )}
        </ForwardTextLink>
    </pui-product-card>);
}
