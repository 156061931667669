import React from 'react';
import { RefundAmount, RefundBottomSheetDetails } from '../component';
import RefundBottomSheet from './RefundBottomSheet';
import { formatCurrency, formatReturnAmount } from '../common/FormatUtils';
import { getLocale } from '../../Utils'
import ErrorBoundary from '../../ErrorBoundary';

interface RefundV2Prop {
    refundBottomSheetDetails?: RefundBottomSheetDetails,
    refundDetails: string,
    loading?: boolean,
}

interface RefundV2State {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
}

class RefundRowV2 extends React.Component<RefundV2Prop, RefundV2State> {

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        this.state.bottomSheet.current.show();
    }

    _findAndRemoveByKey(array: any[], key: any) {
        let result = undefined;
        const pos = array.findIndex(el => el.name === key);
        if (pos >= 0) {
            result = array.splice(pos, 1)[0];
        }
        return result;
    }

    _hasPositiveRefundAmount(refundAmount: RefundAmount) {
        return refundAmount && refundAmount.amount && (parseFloat(refundAmount.amount) > 0)
    }

    render() {
        if (this.props.loading) {
            return (
                <pui-stack direction='horizontal'>
                    <pui-stack-item>
                        <div className="pui-loading-text-medium small-margin-top small-margin-bottom" />
                    </pui-stack-item>
                    <pui-stack-item>
                        <div className="pui-loading-text-medium small-margin-top small-margin-bottom" />
                    </pui-stack-item>
                </pui-stack>
            )
        }

        if (!this.props.refundBottomSheetDetails?.refundDateGroups || !this.props.refundBottomSheetDetails?.refundTotalRow || !this.props.refundBottomSheetDetails?.refundDetails) {
            return (<></>);
        }

        return (
            <>
                <pui-divider spacingTop='small' spacingBottom='small' />
                <pui-stack
                    direction='horizontal'
                    ref={this.state.button}
                    onClick={(e: any) => this._openBottomSheet(e)}
                    class="clickable">
                    <pui-stack-item flowDirection="horizontal"
                        mainAxisArrangement="space-between">
                        <pui-text textSize="medium" textColor="link" spacingTop="mini" fontWeight="bold" input={this.props.refundDetails} textAlign="left" />
                        <pui-icon slot="icon" imgClass='chevron-right' spacingLeft="medium" spacingRight="medium" spacingTop="small"></pui-icon>
                    </pui-stack-item>
                    <pui-stack-item>
                        <pui-text spacingTop="mini" textColor="link" fontWeight="bold" input={formatCurrency(formatReturnAmount(this.props.refundBottomSheetDetails.refundTotalRow.refundAmount.amount), this.props.refundBottomSheetDetails.refundTotalRow.refundAmount.currency, getLocale())} textAlign="right" />
                    </pui-stack-item>
                </pui-stack>
                <ErrorBoundary>
                    <pui-bottom-sheet id="refund-modal" data-csa-c-content-id="refund-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-detail" hideLink ref={this.state.bottomSheet}>
                    <RefundBottomSheet refundBottomSheetDetails={this.props.refundBottomSheetDetails} />
                    </pui-bottom-sheet>
                </ErrorBoundary>
            </>
        )
    }
}

export default RefundRowV2;