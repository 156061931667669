import React from "react"
import {ItemGroup, ItemGroupSummary, OrderTracker, OrderTrackerStep, PurchaseHistory} from "../component";
import TrackerSteps, {TrackerStep, TrackerStepStatus} from "./TrackerSteps";
import HeavyDivider from "../common/HeavyDivider";
import {ListDelimiter} from "../common/ListDelimiter";
import ErrorBoundary from "../../ErrorBoundary";
import {ForwardLink} from "../common/ForwardLink";
import ItemMedicationSummaryCard from "../common/ItemMedicationSummaryCard";
import MedicationCardAutoRefillLine from "../common/MedicationCardAutoRefillLine";
import DeliveryTracker, {PrimaryActionStyle} from "../delivery/DeliveryTracker";
import DeliveryPhotoComponent from "./DeliveryPhotoComponent";
import {TrackerPageBreadCrumb} from "../common/BreadCrumb";
import MedicationCard, {CardStyle} from "../common/MedicationCard";
import {AboveTheFoldMarker, CriticalFeatureMarker, FunctionalMarker} from "../csa/markers";
import ReportDamagedOrMissingMedicationsBottomSheet from "./ReportDamagedOrMissingMedicationsBottomSheet";
import ItemAnnotations from "../common/ItemAnnotations";
import { filterAnnotations } from "../common/AnnotationUtils";
import {
    generateCsaTagSuffix,
    generateUrlWithRefTag,
    PILL_PACK_REF_TAGS,
    REF_TAGS
} from "../csa/utils";

interface TrackerPageProps {
    orderTracker: OrderTracker;
    itemGroupSummary: ItemGroupSummary;
    purchaseHistory: PurchaseHistory;
}

function mapEventUpdateLine(trackerSteps: OrderTrackerStep[]): TrackerStep[] {
    const mapStatus = (status: string) => {
        switch (status) {
            case "COMPLETE": {
                return TrackerStepStatus.COMPLETE;
            }
            case "IN_PROGRESS": {
                return TrackerStepStatus.IN_PROGRESS;
            }
            case "PENDING": {
                return TrackerStepStatus.PENDING;
            }
            default: {
                return TrackerStepStatus.PENDING
            }
        }
    }

    return trackerSteps.map(
        step => {
            return {label: step.label, status: mapStatus(step.status), eventUpdateLine: step.completionDate}
        }
    );
}

function TrackerPage(props: TrackerPageProps) {
    const generateAllUpdatesLink = () => {
        if (!props.orderTracker.delivery || props.orderTracker.delivery.status.status === "NotShipped" ||
            props.orderTracker.delivery.status.status === "None") {
            return <span style={{ marginBottom: "20px" }}/>;
        } else {
            return <span style={{marginBottom: "20px"}}>
                <DeliveryTracker headline={props.itemGroupSummary.headline}
                                 label={props.orderTracker.delivery?.label || props.itemGroupSummary.note}
                                 delivery={props.orderTracker.delivery}
                                 primaryActionStyle={PrimaryActionStyle.LINK}
                                 primaryActionLabel="See all updates"/>
            </span>
        }
    }

    const generateDeliveryPhotoInformation = () => {
        if (props.orderTracker.delivery?.status.status !== "Delivered") {
            return <></>;
        } else {
            return <>
                {props.orderTracker.delivery.deliveryPhoto!=null && <DeliveryPhotoComponent deliveryPhoto={props.orderTracker.delivery.deliveryPhoto} width = "288px" height = "384px"/>}
            </>;
        }
    }

    const generateReportDamagedOrMissingMedications = () => {
        if (props.orderTracker.delivery?.status.status !== "Delivered") {
            return null;
        } else {
            return <ReportDamagedOrMissingMedicationsBottomSheet
              reportDamagedOrMissingMedications={props.orderTracker.labels.reportDamagedOrMissingMedications}/>
        }
    }

    const generateCarrierInformation = () => {
        return props.orderTracker.delivery &&
            <>
                <pui-text input={`Carrier: ${props.orderTracker.delivery.carrier}`}/>
                <pui-text input={`Tracking ID: ${props.orderTracker.delivery.trackingNumber}`}/>
                {generateAllUpdatesLink()}
            </>;
    }

    const generateOrderSummaryItem = (i: number, group: ItemGroup, isBottle?: boolean) => {
        const orderSummaryUrl = generateUrlWithRefTag(`/order/${group.id}`,
          isBottle === true ? PILL_PACK_REF_TAGS.TRACKER_TO_SUMMARY : REF_TAGS.TRACKER_TO_SUMMARY);
        return <React.Fragment key={i}>{group.itemSummaries.map((summary, j) => {
                const autoRefillCardLine = <MedicationCardAutoRefillLine
                    isAutoRefill={summary.isAutoRefill}
                    autoRefillTextDisplay={props.itemGroupSummary.orderingChannelLine}/>;
                return (<React.Fragment key={j}>
                    <ErrorBoundary>
                        <ForwardLink url={orderSummaryUrl} csaId='order'>
                            <ItemMedicationSummaryCard
                                purchaseItemSummary={summary}
                                showSupply={true}
                                autoRefillCardLine={autoRefillCardLine}
                                cardStyle={CardStyle.HEADLINE_MED_50PX_IMG}
                            />
                        </ForwardLink>
                    </ErrorBoundary>
                </React.Fragment>);
            }
        )}</React.Fragment>;
    }
    /**
     * generate PillPackOrderSummary for TrackerPage
     * todo: check the forwardLink url is correct
     */
    const generatePillPackOrderSummary = () => {
        if (props.itemGroupSummary?.pillPackGroupSummary?.itemGroups?.length > 0) {
            const pillPackGroupSummary = props.itemGroupSummary.pillPackGroupSummary;
            const orderSummaryUrl = generateUrlWithRefTag(`/order/${pillPackGroupSummary?.id}`,
              PILL_PACK_REF_TAGS.TRACKER_TO_SUMMARY);
            return <ErrorBoundary>
                <ForwardLink url={orderSummaryUrl} csaId={'pillpack-order'}>
                    <MedicationCard headline={pillPackGroupSummary.headline} subline={pillPackGroupSummary.subline}
                                    isPillPack={true}/>
                </ForwardLink>
                {pillPackGroupSummary?.annotations &&
                    <ItemAnnotations annotations={filterAnnotations(pillPackGroupSummary?.annotations,
                            new Set<string>(["Refund"]))}/>}
            </ErrorBoundary>
        }
        return null;
    }

    /**
     * Generates the order summary items for the tracker page
     * Generate PillPack Order Summary
     * Generate Bottle Order Summary
     * Generate Other Order Summary
     */
    const generateOrderSummaryItems = () => {
        const bottleMedsLength = props.itemGroupSummary?.bottleGroupSummary?.itemGroups?.length;
        return <ListDelimiter delimiter={<pui-divider spacingTop="small" spacingBottom="medium"/>}>
            {generatePillPackOrderSummary()}
            {bottleMedsLength > 0 && props.itemGroupSummary.bottleGroupSummary.itemGroups.map((group, i) =>
              generateOrderSummaryItem(i, group, true)
            )}
            {props.itemGroupSummary.itemGroups.map((group, i) =>
                generateOrderSummaryItem(bottleMedsLength ? i + bottleMedsLength : i, group)
            )}
        </ListDelimiter>;
    }

    const hasBottleOrPackets = () => {
        const bottleMedsLength = props.itemGroupSummary?.bottleGroupSummary?.itemGroups?.length;
        const pillPackMedsLength = props.itemGroupSummary?.pillPackGroupSummary?.itemGroups?.length;
        return bottleMedsLength > 0 || pillPackMedsLength > 0
    }

    return <>
        <pui-stack id={`tracker-page${generateCsaTagSuffix(hasBottleOrPackets())}`}
                   data-csa-c-content-id={`tracker-page${generateCsaTagSuffix(hasBottleOrPackets())}`}
                   data-csa-c-type="card"
                   data-csa-c-slot-id="tracker">
            <TrackerPageBreadCrumb 
                    yourOrders={props.purchaseHistory.labels.yourOrders} 
                    tracking={props.orderTracker.labels.tracking} 
            />
            <pui-heading input={props.orderTracker.labels.tracking} headingLevel="1" textSize="large"
                         spacingBottom="medium"/>
            <pui-heading input={props.itemGroupSummary?.headline} headingLevel="2" spacingBottom="medium"/>
            <pui-heading input={props.orderTracker.headline} headingLevel="3" textSize="small"
                         spacingBottom={props.orderTracker.subline ? "mini" : "medium"}/>
            {props.orderTracker.subline &&
                <pui-text input={props.orderTracker.subline} spacingBottom="medium"/>}
            {generateDeliveryPhotoInformation()}
            <TrackerSteps steps={mapEventUpdateLine(props.orderTracker.trackerSteps)}/>
            <AboveTheFoldMarker/>
            <CriticalFeatureMarker/>
            <pui-text input={props.orderTracker.labels.timeAlert} textSize="small" spacingTop="medium"/>
        </pui-stack>
        {generateReportDamagedOrMissingMedications()}
        <HeavyDivider/>
        <pui-heading input={props.orderTracker.labels.orderSummary} headingLevel="2" textSize="medium"
                     spacingBottom="small"/>
        {generateOrderSummaryItems()}
        {props.itemGroupSummary.address.lines.length > 0 &&
            <pui-stack>
                <HeavyDivider/>
                <pui-heading input={props.orderTracker.labels.deliveryInformation} text="large" spacingBottom="medium"/>
                {generateCarrierInformation()}
                <pui-text input={props.purchaseHistory.labels.deliveryAddress} fontweight="bold" />
                <pui-stack direction="horizontal" secondaryAxisArrangement="center">
                    <pui-stack direction="vertical" width="100%">
                        {props.itemGroupSummary.address.lines.map(value => <pui-text key={value} input={value}/>)}
                    </pui-stack>
                </pui-stack>
            </pui-stack>}
        <FunctionalMarker/>
    </>
}

export default TrackerPage;