import { CsrfToken } from '../components/component';

export default async function cancelOrder(purchaseId: string, orderIds: string[], cancelReason: string | null, csrf?: CsrfToken) {  
  
  let headers: any = {
    'Content-Type': 'application/json; charset=UTF-8',
  };

  if (csrf) {
    headers[csrf.header] = csrf.token;
  }
  
  const response = await fetch('/your-orders-api/command/cancel', {
    method: 'POST',
    credentials: 'same-origin',
    headers,
    body: JSON.stringify({ purchaseId, orderIds, cancelReason})
  })
  return response.json();
}
