import React from 'react';
import ExternalLink from './ExternalLink';
import { I18NContext } from '../../I18NContext';
import Urls from '../../Urls';

interface ManagePillpackSettingsProps {
    managePillpackSettings: string;
}

export class ManagePillpackSettings extends React.Component<ManagePillpackSettingsProps, {}> {
    render() {
        return (<ExternalLink href={Urls.PILLPACK_SETTINGS}>
            <pui-stack id="link-to-pillpack-settings" data-csa-c-type="link" data-csa-c-action="navigate" data-csa-c-content-id="link-to-pillpack-settings"
                       data-csa-c-slot-id="manage-pillpack-settings" direction="vertical">
                <pui-stack-item-button buttonPadding="none">
                    <pui-text input={this.props.managePillpackSettings} textSize='medium' color="black" paddingTop="medium" paddingBottom="medium"/>
                </pui-stack-item-button>
            </pui-stack>
        </ExternalLink>);
    }
}

ManagePillpackSettings.contextType = I18NContext;

export default ManagePillpackSettings;