import React from 'react';
import { I18NContext } from '../../I18NContext';
import { formatMonDDYYY } from '../common/FormatUtils';
import HeavyDivider from '../common/HeavyDivider';
import { TransactionFilters } from '../TransactionFilters'
import { ListDelimiter } from '../common/ListDelimiter';
import { Invoice, Order} from '../component';
import './Invoice.scss';
import './InvoiceAttributeRow';
import InvoiceAttributeRow from './InvoiceAttributeRow';
import InvoiceCharges from './InvoiceCharges';
import InvoiceItemSummary from './InvoiceItemSummary';
import ErrorBoundary from '../../ErrorBoundary'
import { InvoiceComponentBreadCrumb } from '../common/BreadCrumb';
import {getLocale, checkRebillOrder} from '../../Utils';
import {AboveTheFoldMarker, FunctionalMarker} from "../csa/markers";
import { unlockPageScroll } from '../common/ScrollUtility';

interface InvoiceComponentProps {
    invoice: Invoice;
    deliveryId?: string;
}

class InvoiceComponent extends React.Component<InvoiceComponentProps, {}>  {

  render() {

        unlockPageScroll();
        const { invoice } = this.props;
        const order = invoice.order;
        const isRebillOrder = checkRebillOrder(order);

        return (
            <pui-stack id="invoice-page" data-csa-c-content-id="invoice-page" data-csa-c-type="card" data-csa-c-slot-id="invoice" style={{ width: "100%" }}>
                <InvoiceComponentBreadCrumb 
                    orderId={order.id}
                    yourOrders={order.invoicePageLabels.yourOrders} 
                    delivery={order.invoicePageLabels.delivery}
                    tracking={order.invoicePageLabels.tracking}
                    order={order.invoicePageLabels.order} 
                    invoice={order.invoicePageLabels.invoice}
                    isRebillOrder={isRebillOrder}
                    bypassTracker={order.bypassTracker}
                />
                <pui-heading input={order.headline} spacingTop="small" />
                <pui-text input={order.subline} />
                <HeavyDivider />
                <ErrorBoundary> 
                    <ListDelimiter delimiter={<HeavyDivider />} >
                        {order.items.map((item, i) => <InvoiceItemSummary order={order} item={item} key={i + '-invoicecomponent'} />)}
                    </ListDelimiter>
                </ErrorBoundary>
                <AboveTheFoldMarker/>
                <HeavyDivider />
                {this._generateOrderInformation(order, isRebillOrder)}
                {(order.charges.hbaEligibleTotal !== "$0.00" && !!order.charges.hbaEligibleTotal) &&
                    <ErrorBoundary>
                    <pui-stack>
                        <HeavyDivider />
                        <pui-heading input={order.charges.hbaEligibleTotalLabel} />
                        <pui-text input={order.charges.hbaEligibleTotal} />
                    </pui-stack>
                    </ErrorBoundary>}
                <FunctionalMarker/>
            </pui-stack>
         );
    }

    // This is not quite right!
    // We would usually see n many | n >= 1 Transactions for each order
    // We could also see n many items | n >= 1 for each order.
    // As this stands, it won't reflect a reversed Transactions aka refund.
    // A Partial Refund would come from CROW and is not represented here ATM.
    _generateOrderInformation(order: Order, isRebillOrder : boolean) {
        const [firstItem] = order.items;
        const [firstTransaction] = order.transactions;

        const paymentDetails = TransactionFilters.DistinctLabels(order.transactions)
            .map(transaction => transaction.label || "");
        // To be clear, this is WRONG for the transaction date.
        // we can add that and return it from server as it's on ChargeInfo.
        if (order.orderedOn) {
            const transactionDate = formatMonDDYYY(order.orderedOn.value, getLocale());
            paymentDetails.push(`${order.invoicePageLabels.transactionOn} ${transactionDate}`);
        }

        return (
            <pui-stack direction="vertical">
                <pui-heading input={order.invoicePageLabels.orderInformation} />
                <table style={{ width: '100%' }}>
                    <tbody>
                        <InvoiceAttributeRow label={order.invoicePageLabels.orderId} value={order.orderId} />
                        {order.orderedOn && <InvoiceAttributeRow label={order.invoicePageLabels.orderDate} value={formatMonDDYYY(order.orderedOn.value, getLocale())} />}
                        {   // Not show delivery address in the invoice if it's a rebill order
                            firstItem.delivery?.address?.lines?.length > 0 && <InvoiceAttributeRow label={order.invoicePageLabels.deliveryAddress} values= {isRebillOrder ? [] :firstItem.delivery.address.lines} />
                        }
                        <ErrorBoundary> {!isRebillOrder && <InvoiceAttributeRow label={order.invoicePageLabels.shipSpeed} value={firstItem.shipSpeed?.description} />} </ErrorBoundary>
                        <ErrorBoundary> <InvoiceAttributeRow label={order.invoicePageLabels.payments} values={paymentDetails} /> </ErrorBoundary>
                        {firstTransaction?.address?.lines?.length && <ErrorBoundary> <InvoiceAttributeRow label={order.invoicePageLabels.billingAddress} values={firstTransaction.address.lines} /> </ErrorBoundary>}
                        <ErrorBoundary> <InvoiceCharges {...order.charges} /> </ErrorBoundary>
                    </tbody>
                </table>
            </pui-stack>
        )
    }

}

InvoiceComponent.contextType = I18NContext;

export default InvoiceComponent;