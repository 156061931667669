import React from "react"
import { ItemGroupSummary, PurchaseHistory } from '../component'
import DeliveryDetails from '../delivery/DeliveryDetails';
import { Cache } from 'aws-amplify/utils';
import { Redirect } from 'react-router-dom';
import { getOrderHistory, getDeliveryDetails } from '../../accessors/GraphQLAccessor';
import DeliveryDetailsLoading from './DeliveryDetailsLoading';
import { AppContext } from '../../AppContext';

import appMetrics from '../../common/MetricsService';
import MetricsConstants from '../../common/MetricsConstants';
import * as KatalMetrics from '@amzn/katal-metrics';
class DeliveryDetailsHOC extends React.Component<any, any> {

    actionMetricsPublisher: any;
    attemptMetric: any;

    constructor(props: any) {
        super(props);
        this.state = {
            deliveryID: null,
            view: <DeliveryDetailsLoading/>
        };
        this.actionMetricsPublisher = appMetrics.getMetricsPublisherForMethod("DeliveryDetails");
    }

    selectItemGroupSummary(itemGroupSummary: ItemGroupSummary, purchaseHistory: PurchaseHistory) {
        this.setState({ 
            view: <DeliveryDetailsLoading/> 
        });

        const deliveryID = itemGroupSummary.id;
        Cache.setItem("deliveryID", deliveryID);
        getDeliveryDetails(deliveryID)
            .then((response: any) => {
                this.setState({
                    view: (<DeliveryDetails delivery={response.data.delivery} itemGroupSummary={itemGroupSummary} purchaseHistory={purchaseHistory} />)
                });
                this.attemptMetric.setSuccess();
                this.actionMetricsPublisher.publish(this.attemptMetric);
            })
            .catch(() => 
            {
                this.setState({view: <DeliveryDetails itemGroupSummary={itemGroupSummary} purchaseHistory={purchaseHistory}/>});
                this.attemptMetric.setFailure();
                this.actionMetricsPublisher.publish(this.attemptMetric);
            }
        )
        this.setState({ deliveryID, itemGroupSummary: itemGroupSummary });
    }

    componentDidMount() {
        this.attemptMetric = new KatalMetrics.Metric.TimedAttempt(MetricsConstants.METRIC_LOAD_PAGE_DATA).withMonitor();
        const deliveryId = this.props.match.params.deliveryId || Cache.getItem("deliveryID");
        const [orderHistory]: ItemGroupSummary[] = []
            .concat(this.context.getPurchaseHistory().upcoming, this.context.getPurchaseHistory().past)
            .filter((itemGroup: ItemGroupSummary) => itemGroup?.id === deliveryId);
        
            
        if(orderHistory) {
            this.setState({
                view: <Redirect to={`/progress-tracker/${deliveryId}`}/>
            });
            return;
        }
        else {
            // When a customer enters PYO through Dashboard, they navigate directly to the delivery details component.
            // That means nothing is set in the context since they didn't enter through the order history component. 
            // Fetch for the last 90 days in order history and let's see if a item is found.
            getOrderHistory()
                .then((response: any) => {
                    this.context.addPurchaseHistory(response.data.purchaseHistory);
                    const [orderHistoryResponse] = []
                        .concat(response.data.purchaseHistory.upcoming, response.data.purchaseHistory.past)
                        .filter((itemGroup: ItemGroupSummary) => itemGroup.id === deliveryId);
                    if (!orderHistoryResponse) {
                        this.setState({
                            view: <Redirect to="/your-orders"/>
                        });
                    } else {
                        this.setState({
                            view: <Redirect to={`/progress-tracker/${deliveryId}`}/>
                        });
                        return;
                    }
                    this.attemptMetric.setSuccess();
                    this.actionMetricsPublisher.publish(this.attemptMetric);
                })
                .catch(() => 
                {
                    this.setState({
                        view: <>Error on order history :( </>,
                    });
                    this.attemptMetric.setFailure();
                    this.actionMetricsPublisher.publish(this.attemptMetric);
                })
        }
    }

    render() {
        return (this.state.view);
    }
}

DeliveryDetailsHOC.contextType = AppContext;

export default DeliveryDetailsHOC;