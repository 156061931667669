import React from "react"
import InvoiceComponent from './InvoiceComponent';
import { getInvoiceDetails } from '../../accessors/GraphQLAccessor';
import InvoiceComponentLoading from './InvoiceComponentLoading';

import appMetrics from '../../common/MetricsService';
import MetricsConstants from '../../common/MetricsConstants';
import * as KatalMetrics from '@amzn/katal-metrics';
import { AppContext } from '../../AppContext';

class InvoiceDetailHOC extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      deliveryID: null,
      view: <InvoiceComponentLoading/>
    };
  }

  componentDidMount() {
    const actionMetricsPublisher = appMetrics.getMetricsPublisherForMethod("Invoice");
    const attemptMetric = new KatalMetrics.Metric.TimedAttempt(MetricsConstants.METRIC_LOAD_PAGE_DATA).withMonitor();

    const orderId = this.props.match.params.invoiceId;

    getInvoiceDetails(orderId)
      .then((response: any) => {
        this.setState({
          view: <InvoiceComponent invoice={response.data.invoice}/>
        });
        attemptMetric.setSuccess();
        actionMetricsPublisher.publish(attemptMetric);
      })
      .catch(() => {
        this.setState({
          view: (<pui-text id="invoice-page-error" data-csa-c-content-id="invoice-page-error" data-csa-c-type="element" data-csa-c-slot-id="invoice">Error</pui-text>),
        });
        attemptMetric.setFailure();
        actionMetricsPublisher.publish(attemptMetric);
      });

  }

  render() {
    return (this.state.view);
  }
}

InvoiceDetailHOC.contextType = AppContext;

export default InvoiceDetailHOC;
