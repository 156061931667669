const RebillOrderType = {
	"REBILL_ORDER": "REBILL_ORDER",
	"NORMAL_ORDER": "NORMAL_ORDER",
	"PARENT_ORDER": "PARENT_ORDER",
	"REBILL_AND_PARENT_ORDER": "REBILL_AND_PARENT_ORDER"
}

export default RebillOrderType;

export enum GroupType {
	PILLPACK = "PILLPACK",
	BOTTLE = 'BOTTLE',
	OTHER = 'OTHER'
}