import FeatureFlags from './components/common/FeatureFlags';
import { PurchaseHistory } from './components/component';

// Goal of this class is contain information that can be used across multiple PYO pages
// Right now this is just Feature Flags and purchase history, but can be expanded (or reduced) in the future
class AppContextClass {
    featureFlags: FeatureFlags;
    purchaseHistory: PurchaseHistory;

    private defaultPurchaseHistory: PurchaseHistory = {
        upcoming: [],
        past: [],
        pillPackDeliveryScheduleCard: {
            isUserEnrolledForPillPack: false
        },
        labels: {
            yourOrders: "",
            delivery: "",
            itemsInThisDelivery: "",
            deliveryAddress: "",
            seeMoreOrders: "",
            seeMoreOrdersLoading: "",
            seeMoreOrdersFailure: "",
            ordersOlderThan90Days: "",
            questions: "",
            contactCustomerCare: "",
            helpLink: "",
            pastOrders: "",
            pastOrdersSubline: "",
            orderId: "",
            noUpcomingOrders: "",
            getMedicationOrderStatements: "",
            upcomingOrdersTab: "",
            pastOrdersTab: "",
            trackDelivery: "",
            seeDetails: "",
        },
        iosDevice: false,
        androidDevice: false,
        mShop: false
    };

    constructor() {
        this.featureFlags = new FeatureFlags();
        this.purchaseHistory = this.defaultPurchaseHistory;
    }

    async pullFeatureFlags() {
        await this.featureFlags.pullFeatureFlags();
    }

    getFeatureFlags() {
        return this.featureFlags;
    }

    addPurchaseHistory(_purchaseHistory: PurchaseHistory) {
        if (_purchaseHistory.upcoming) {
            this.purchaseHistory.upcoming = this.purchaseHistory.upcoming.concat(_purchaseHistory.upcoming);
        }

        if (_purchaseHistory.past) {
            this.purchaseHistory.past = this.purchaseHistory.past.concat(_purchaseHistory.past);
        }

        if (_purchaseHistory.labels) {
            this.purchaseHistory.labels = _purchaseHistory.labels;
        }
    }

    getPurchaseHistory() {
        return this.purchaseHistory;
    }

    resetFeatureFlags() {
        this.featureFlags = new FeatureFlags();
    }

    resetPurchaseHistory() {
        this.purchaseHistory = this.defaultPurchaseHistory;
    }
}

export default AppContextClass;
